import { createContext } from 'react';

var AppState = {
	eng:{display:'none'},
	tc:{display:'inline'},
	sc:{display:'none'},
	lang:'tc'
}

function AppReducer(state, action){
	switch(action.type){		
		case "setLang":
			switch(action.value){
				case "tc":	
					AppState = {
						eng:{display:'none'},
						tc:{display:'inline'},
						sc:{display:'none'},
						lang:'tc'
					};				
					return AppState;					
				break;
				
				case "sc":		
					AppState = {
						eng:{display:'none'},
						sc:{display:'inline'},
						tc:{display:'none'},
						lang:'sc'
					};					
					return AppState;
				break;
				
				case "eng":	
					AppState = {
						eng:{display:'inline'},
						sc:{display:'none'},
						tc:{display:'none'},
						lang:'eng'
					};						
					return AppState;
				break;
				
			}
		break;		
	}
	return state;
}

const Context = createContext(AppState);
export { Context, AppReducer, AppState };