import logo from './img/cuhk_logo_2x.png';

import './App.css';
import {useRef,useState,useEffect,useReducer} from 'react';
import { Context, AppState, AppReducer } from './context';
import React from 'react';
import { FaCalendarAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { URL0,blue } from './config';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {	
	
	const [message,setMessage] = useState('');	
	const sidRef = useRef();	
	const passwordRef = useRef();
	const [msg,setMsg] = useState('');
	const [show1,setShow1] = useState(false);
	const navigate = useNavigate();
	const [redirect,setRedirect] = useState(false);
		
	const [state,dispatch] = useReducer(AppReducer,AppState);
	const lang = state.lang;	
	//console.log('reg ' , langState);
		
	const reg = () => {		
		if (sidRef.current.value==''){			
			if (lang=='eng') alert('Please input Student id');
			if (lang=='tc') alert('請輸人Student id');
			if (lang=='sc') alert('请输入Student id');
			return ;
		}
		let data = {
			sid:sidRef.current.value,			
		};
		
		fetch(URL0 + '/cuhk2_frontend.php?action=register', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {			
			},
		})
		.then((res) => res.text())
		.then((data) => {
			//console.log(data);
			data = JSON.parse(data);
			if (data['result']==1){				
				setShow1(true);
				setMsg(data['msg'][lang]);				
			}
		})
		.catch((err) => console.error(err));
	}
	
	const keyup_1 = (p) => {
		if (p.keyCode==13){			
			reg();
		}
	}
	
	return (
	<Context.Provider value={{state,dispatch}} >
    <div className="container-lg"  >	
		
		<Modal show={show1} onHide={()=>setShow1(false)}>
			<Modal.Header closeButton>
			<Modal.Title>
				{lang=='eng'?'Message':''}
				{lang=='tc'?'訊息':''}
				{lang=='sc'?'讯息':''}	
			</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{msg} 
			</Modal.Body>
			 <Modal.Footer>
			<Button variant="secondary" onClick={()=>setShow1(false)}>
			Close
			</Button>
			
			</Modal.Footer>
		</Modal>			
     
		<div style={{
				marginTop:"10px",
				paddingTop:"20px",
				backgroundColor:"#FFFFFF",
				border:"0px solid black",								
				
				filter: "drop-shadow(1px 1px 5px #808080)",
				}} className="container-lg">
				
				<div style={{display:'flex',justifyContent:'space-between',
					flexDirection:'column',width:"100%",border:"0px solid black"}}>
					<img src={logo} className="cuhklogo" />
								
					<div style={{flexDirection:'column',
								justifyContent:'flex-end',
								display:'flex',						
								alignItems:'flex-end',
								border:"0px solid black",
								paddingRight:'0px'}}>
						<div>					
						</div>
						<div >					
							<span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=> { dispatch({type:'setLang',value:'tc'}); }} > 繁 </span>
							<span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=> { dispatch({type:'setLang',value:'sc'});  }} > 简 </span>
							<span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=> { dispatch({type:'setLang',value:'eng'}); }} > Eng </span>					
						</div>		
					</div>					
				</div>			
				
				<div className="mm1" style={{backgroundColor:"#FFFFFF",display:"flex",justifyContent:"space-between"}}>
				
					<div style={{width:"100%",border:"0px solid black"}}>
					
						
						<hr style={{marginTop:"40px",marginBottom:"40px"}} />
												
						<div style={{border:"0px solid black",color:blue,fontSize:"20px"}} > 
							
							{lang=='eng'?'Registration':''}
							{lang=='tc'?'註冊':''}
							{lang=='sc'?'注册':''}							
							
						</div>						

						<br />			
											
						<div className="input-group" style={{border:"0px solid black",textAlign:'center'}}>
							<span className="input-group-text inputfield1">
							
							{lang=='eng'?'Student No.':''}
							{lang=='tc'?'學生號碼':''}
							{lang=='sc'?'学生号码':''}
							
							</span>
							<input onKeyUp={e=>keyup_1(e)} ref={sidRef} type="text"  placeholder={(lang=='eng'?'Student No.':'') + (lang=='tc'?'學生號碼':'') + (lang=='sc'?'学生号码':'') }
							aria-describedby="basic-addon1" style={{width:'200px'}}/>
							<span className="input-group-text inputfield1">@link.cuhk.edu.hk</span>
						</div>	
						
						<br />
						
						<div style={{marginTop:"20px",marginBottom:"20px"}}>
							<input  style={{border:"2px solid "+blue,backgroundColor:blue,color:'white',width:"100%"}} type='button' 
							value={ (lang=='eng'?'Send the password to your mailbox':'') + (lang=='tc'?'密碼發送到你的郵箱':'') + (lang=='sc'?'密码发送到你的邮箱':'') } 
							onClick={()=>reg()} />
						</div>
												
						<br />
												
						<div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:'flex-end'}}>
						
							<div style={{marginTop:"20px",marginBottom:"20px",marginRight:'40px'}}>
								<span style={{cursor:'pointer'}} onClick={()=> navigate("/login", { replace: true })  } >  
									{lang=='eng'?'Back to Login Page':''}
									{lang=='tc'?'返回登入畫面':''}
									{lang=='sc'?'返回登入画面':''}
								
								</span>										 
							</div>	
						</div>
						
					</div>
					
				</div>		
				<br />				
			</div>
		
	</div>
	
	</Context.Provider >
	)
}
		

export default App;
