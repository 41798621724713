import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Choose from './Choose';
import Login from './Login';
import Reg from './Reg';
import Resend from './Resend';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter,Routes,Route,Link,useParams  } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter>
		<Routes>
			<Route path={'/'} element={<Choose />} />			
			<Route path={'/choose'} element={<Choose />} />									
			<Route path={'/reg'} element={<Reg />} />						
			<Route path={'/login'} element={<Login />} />									
			<Route path={'/resend'} element={<Resend />} />	
		</Routes>
	</BrowserRouter>
	)
	

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
