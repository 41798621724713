
import logo from './img/cuhk_logo_2x.png';
import './App.css';
import {useRef,useState,useEffect,useReducer} from 'react';
import React from 'react';
import { Context, AppState, AppReducer } from './context';
import { FaCalendarAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { URL0 , blue, attachTypeLabel } from './config';

import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
			
function Star() {
	return <span style={{color:'red'}}>*</span>	
}

const prefChosen = {
	backgroundColor:'#64eb34',
	color:'blue',
}
function App() {
	
	const navigate = useNavigate();
	const [message,setMessage] = useState('');
	const [sysmessage,setSysmessage] = useState('');
	const [show1,setShow1] = useState(false);	
	const [init,setInit] = useState(false);	
	const [ticket,setTicket] = useState(false);	
	const [hallname,setHallname] = useState('');
	const [studentname,setStudentname] = useState('');
	const [sid,setSid] = useState('');
	
	const [state,dispatch] = useReducer(AppReducer,AppState);
	const lang = state.lang;
	const eng = state.eng;
	const sc = state.sc;
	const tc = state.tc;	
	
	const [pref2,setPref2] = useState({});
	const [pref3,setPref3] = useState({});
	const [prefC3,setPrefC3] = useState({});
	const [roomtype,setRoomtype] = useState('');
	const [agree,setAgree] = useState(false);
	const [amend,setAmend] = useState(false);
	
	
	const sid1Ref = useRef();
	const sid2Ref = useRef();
	const agreeRef = useRef();
	
	const sysmsg = (x) => {
		setMessage('');
		setSysmessage(x);		
		setShow1(true);
		//console.log(x);
	}
	
	useEffect( () => {
		
		if (roomtype=='2'){			
			setPref2({...prefChosen});
			setPref3({});
			setPrefC3({});		
			sid2Ref.current.value = '';
		}		
		if (roomtype=='3'){
			setPref3({...prefChosen});
			setPref2({});
			setPrefC3({});	
		}		
		if (roomtype=='C3'){
			setPrefC3({...prefChosen});
			setPref2({});
			setPref3({});	
		}		
		if (roomtype==''){
			setPrefC3({});
			setPref2({});
			setPref3({});	
		}	
		
	},[roomtype]);		
	
	const cancelapply = () => {
		fetch(URL0 + '/cuhk2_frontend.php?action=cancelapply', {
			method: 'POST',			
			headers: {
				'JWT': localStorage.getItem('jwtapplication')
			},
		})
		.then((res) => res.text())
		.then((data) => {
			console.log(data);
			//return ;
			data = JSON.parse(data);			
			if (data['result']){	
				if (data['result']=='1'){
					setShow1(true);
					setSysmessage(data['msg'][lang]);
					resume();
				} 			
			} else {
				navigate("/login", { replace: true })
			}
		})
		.catch((err) => console.log('error' , err));
	}

	const resume = () => {
		fetch(URL0 + '/cuhk2_frontend.php?action=resume', {
			method: 'POST',			
			headers: {
				'JWT': localStorage.getItem('jwtapplication')
			},
		})
		.then((res) => res.text())
		.then((data) => {
			console.log(data);
			data = JSON.parse(data);
			//console.log(data);
			if (data['result']){
								
				setInit(true);
				
				if (data['result']=='1'){
					setHallname(data['hall_name']);
					setStudentname(data['student_name']);
					setSid(data['sid']);
					if (data['ticket'].length>0){
						setTicket(data['ticket'][0]);
						setAmend(false);
					} else{
						setTicket(false);
						setAmend(true);
					}
					if (data['pref'].length>0){
						setRoomtype(data['pref'][0]['roomtype']);
						if (data['pref'][0]['sid1']){
							sid1Ref.current.value = data['pref'][0]['sid1'];
						}
						if (data['pref'][0]['sid2']){
							sid2Ref.current.value = data['pref'][0]['sid2'];
						}
					} else {
						setRoomtype('');
						sid1Ref.current.value = '';
						sid2Ref.current.value = '';
					}
				} 
				if (data['result']=='2'){
					setShow1(true);
					setSysmessage(data['msg'][lang]);
				} 				
				
			} else {
				navigate("/login", { replace: true })
			}
		})
		.catch((err) => console.log('error' , err));
	}
		
	useEffect( () => {		
		resume();
	},[]);
			
	const logout = () => {
		localStorage.setItem('jwtapplication','');
		navigate("/login", { replace: true })
	}		
	
	const submit = () => {				
		let submitdata = {
			roomtype:roomtype,
			sid1:sid1Ref.current?.value,
			sid2:sid2Ref.current?.value,
		};
		
		let url = URL0 + "/cuhk2_frontend.php?action=apply";		
		
		fetch(url, {method: "POST", 
					headers: {
						'JWT': localStorage.getItem('jwtapplication')
					},
					body: JSON.stringify(submitdata) })
		.then(res => res.text())
		.then(res => {	
			console.log(res);			
			res = JSON.parse(res);			
			if (res['result']==2){ // invalid
				if (lang=='eng') sysmsg( res['msg']['eng'] );			
				if (lang=='tc') sysmsg( res['msg']['tc'] );			
				if (lang=='sc') sysmsg( res['msg']['sc'] );
			}
			
			if (res['result']==1){ // saved
				if (lang=='eng') sysmsg( res['msg']['eng'] );			
				if (lang=='tc') sysmsg( res['msg']['tc'] );			
				if (lang=='sc') sysmsg( res['msg']['sc'] );
				resume();
			}
			
		})
		.catch(err => console.log(err))
		.finally(() => {});				
	}
	
	return (
		<Context.Provider value={{state,dispatch}} >
			<div style={{paddingTop:'20px',display:init?'block':'none'}} className="appform container-lg" >
			
				<div style={{display:'flex',justifyContent:'space-between',
					flexDirection:'column',width:"100%",border:"0px solid black"}}>
					<img src={logo} className="cuhklogo" />
								
					<div style={{flexDirection:'column',
								justifyContent:'flex-end',
								display:'flex',						
								alignItems:'flex-end',
								border:"0px solid black",
								paddingRight:'0px'}}>
						<div>					
						</div>
						<div >					
							<span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=> { dispatch({type:'setLang',value:'tc'}); }} > 繁 </span>
							<span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=> { dispatch({type:'setLang',value:'sc'});  }} > 简 </span>
							<span style={{cursor:'pointer',marginLeft:'10px'}} onClick={()=> { dispatch({type:'setLang',value:'eng'}); }} > Eng </span>					
						</div>
						<hr />
						<span> <div >										
							{lang=='eng' && <span style={{cursor:'pointer',marginLeft:'10px'}} onClick={logout} > Logout </span> }
							{lang=='tc' && <span style={{cursor:'pointer',marginLeft:'10px'}} onClick={logout} > 登出 </span> }
							{lang=='sc' && <span style={{cursor:'pointer',marginLeft:'10px'}} onClick={logout} > 登出 </span> }
						</div></span>
						
					</div>
					
				</div>
		
						
				<Modal show={show1} onHide={()=>setShow1(false)}>
					<Modal.Header closeButton>
					<Modal.Title>{lang=='eng'?'Message':''}{lang=='sc'?'讯息':''}{lang=='tc'?'訊息':''}</Modal.Title>
					</Modal.Header>
					{message!='' && <Modal.Body>			
					{lang=='tc'?'請先填妥':''}{lang=='eng'?'Please fill in ':''}{lang=='sc'?'请先填妥':''}「 {message} 」
					</Modal.Body>}
					
					{sysmessage!='' && <Modal.Body>			
					{sysmessage} 
					</Modal.Body>}
					
					 <Modal.Footer>
					<Button variant="secondary" onClick={()=>setShow1(false)}>
					{lang=='eng'?'Close':''}{lang=='sc'?'关闭':''}{lang=='tc'?'關閉':''}
					</Button>
					
					</Modal.Footer>
				</Modal>		
				
				<div style={{width:'100%',paddingLeft:'10px',backgroundColor:blue,color:'white',fontSize:'18px'}}>					
					{lang=='eng'?'NA Student Hostels System':''}
					{lang=='tc'?'新亞學生宿舍系統':''}
					{lang=='sc'?'新亚学生宿舍系统':''}					
				</div>
				<br />
			 
				<div style={{fontSize:"30px",...eng}}> 
				Choose Room type and roommate 
				</div>
				
				<div style={{fontSize:"30px",...tc}}> 		
				選擇房間類型和室友
				</div>
				
				<div style={{fontSize:"30px",...sc}}> 		
				选择房间类型和室友
				</div>
				
				<br />	<br />	
				{lang=='tc' && <div>
				學生名稱 {studentname}<br />		
				
				　　宿舍 {hallname} <br />		
				
				學生編號 {sid} <br />		
				</div>}
				
				{lang=='sc' && <div>
				学生名称 {studentname}<br />		
				
				　　宿舍 {hallname} <br />		
				
				学生编号 {sid} <br />		
				</div>}
				
				{lang=='eng' && <div>
				Student Name {studentname}<br />		
				
				　　Hall {hallname} <br />		
				
				Student ID {sid} <br />		
				</div>}
				
				

					<div style={{display:amend?'block':'none'}}>
						<br />		
						<br />		
						<br />	

				
						<div style={{justifyContent:'center',border:'0px solid red',display:'flex',width:'100%',flexDirection:'column'}}>
						
							<div className="width3" style={{justifyContent:'space-between',width:"100%",
										border:'0px solid red',display:'flex',flexDirection:'column'}}>
								
								<div className="Button1" style={{...pref2}} onClick={()=>setRoomtype('2')} >
									{lang=='eng'?'Double Room':''}
									{lang=='tc'?'二人房':''}
									{lang=='sc'?'二人房':''}
								</div>
								
								<div className="Button1" style={{...pref3}} onClick={()=>setRoomtype('3')}>
									{lang=='eng'?'Triple  Room':''}
									{lang=='tc'?'三人房':''}
									{lang=='sc'?'三人房':''}</div>
								
								<div className="Button1" style={{...prefC3}} onClick={()=>setRoomtype('C3')}>
									{lang=='eng'?'Converted Triple Room':''}
									{lang=='tc'?'改裝三人房':''}
									{lang=='sc'?'改裝三人房':''}
								</div>
							</div>
						</div>
						<br/>
						<br/>
						<br/>
						
						<div style={{justifyContent:'center',border:'0px solid red',display:'flex',width:'100%',flexDirection:'row'}}>
						
							<div className="width3"  style={{justifyContent:'flex-start',border:'0px solid red',display:'flex',flexDirection:'column'}}>
							
								<div style={{display: ['2','3','C3'].indexOf(roomtype)!=-1?'block':'none' , justifyContent:'flex-start',
											border:'0px solid red',
											width:'100%',flexDirection:'row'}}>							 
									{lang=='eng'?'Please enter the roommate student id that you apply for matching':''}		
									{lang=='tc'?'請輸入擬配對室友學生編號':''}		
									{lang=='sc'?'请输入擬配对室友学生编号':''}		

								</div>
								
								<div style={{display: ['2','3','C3'].indexOf(roomtype)!=-1?'block':'none' , justifyContent:'flex-start',border:'0px solid red',width:'100%',flexDirection:'row'}}>
									<div>							
									{lang=='eng'?'Student ID':''}
									{lang=='tc'?'學生編號':''}
									{lang=='sc'?'學生編號':''}
									: <input ref={el=>sid1Ref.current=el} />
									</div>
								</div>
									
								<div style={{display: ['3','C3'].indexOf(roomtype)!=-1?'block':'none' , justifyContent:'flex-start',border:'0px solid red',width:'100%',flexDirection:'row'}}>						
									<div>							
									{lang=='eng'?'Student ID':''}		
									{lang=='tc'?'學生編號':''}		
									{lang=='sc'?'學生編號':''}: <input ref={el=>sid2Ref.current=el} /> 
									</div>							
								</div>
							</div>
						</div>
						
						
						<br/>
						
						
						<input type='checkbox'
								checked={agree}
								onChange={()=>setAgree(!agree)}
								
							/> 
							
							{lang=='eng' && <>
							<u>*Notes for room pairing</u><br />							
							Your successful pairing does not mean that you and your paired roommate(s) must be able to draw a preferred double room/ triple room / converted triple room. In the case of any type of rooms are running in short, your pairing may be allocated to draw other types of room.
							</>}
							
							{lang=='tc' && <>
							<u>*房間配對注意事項</u><br />
							你的成功配對並非代表你和你的室友必定能夠抽到屬意的雙人房/三人房/改裝三人房,如果任何類型的房間不足時,你的配對可能會被分配到其他類型的房間
							</>}
							
							{lang=='sc' && <>
							<u>*房间配对注意事项</u><br />
							你的成功配对并非代表你和你的室友必定能够抽到属意的双人房/三人房/改装三人房,如果任何类型的房间不足时,你的配对可能会被分配到其他类型的房间
							</>}
							
							
							
							
							
							
							
						<br /><br />	
							
						
						<div style={{justifyContent:'center',border:'0px solid red',display:'flex',width:'100%',flexDirection:'row'}}>
						
							<div className="width3"  style={{justifyContent:'flex-start',border:'0px solid red',display:'flex',flexDirection:'column'}}>
							
								<input type='button' disabled={!agree} onClick={()=>submit()} value={ (lang=='eng'?"Submit":'') + (lang=='sc'?"储存":'')  + (lang=='tc'?"儲存":'')   } />
									
							</div>
						</div>
					</div>
					
					
					{ticket && !amend && <div style={{justifyContent:'center',marginTop:'50px',border:'0px solid red',display:'flex',width:'100%',flexDirection:'column'}}>
					
						<div className="width3"  style={{justifyContent:'flex-start',padding:"0px",border:'1px solid black',display:'flex',flexDirection:'column'}}>
						
						
							<div>
								<div>
									<div style={{width:"100%",backgroundColor:'blue',color:'white',textAlign:'center'}}>
									<b>Ticket</b>
									</div>
								
									<table style={{margin:'10px'}}>
										<tbody>
										
										<tr>
											<td> {lang=='eng'?'Ticket ID':''} 	
												{lang=='tc'?'配對編號':''}
												{lang=='sc'?'配对编号':''}: &nbsp;&nbsp;&nbsp;
											</td>
											<td>{ticket['ticketid']}</td>									
										</tr>
										
										<tr>
											<td> {lang=='eng'?'Room Type':''} 	
												{lang=='tc'?'房型':''}
												{lang=='sc'?'房型':''}: &nbsp;&nbsp;&nbsp;
											</td>
											<td>{ticket['roomtype']}</td>									
										</tr>
																								
										<tr>
											<td> {lang=='eng'?'Student':''}{lang=='tc'?'學生':''}{lang=='sc'?'学生':''} 1:	</td>
											<td> {ticket['name1']}</td>									
										</tr>
										
										<tr>
											<td> {lang=='eng'?'Student':''}{lang=='tc'?'學生':''}{lang=='sc'?'学生':''} 2:	</td>
											<td> {ticket['name2']}</td>									
										</tr>
										
										{(ticket['roomtype']=='3' || ticket['roomtype']=='C3') && <tr>
											<td> {lang=='eng'?'Student':''}{lang=='tc'?'學生':''}{lang=='sc'?'学生':''} 3:	</td>
											<td> {ticket['name3']}</td>									
										</tr>}
										
										</tbody>											
									</table>
									
								</div>
							</div>
						
						</div>
						<br />
						{ /*onClick={()=>setAmend(true) }*/ }
						<div> 	<input type='checkbox' onClick={()=>cancelapply() }  /> 
							{lang=='eng'?'Click here for pairing again.# The original Room Pairing and Ticket ID will be cancelled.':''}
							{lang=='tc'?'如需重新配對, 請選擇此項# 原本的房友配對和Ticket號碼會被取消。':''}
							{lang=='sc'?'如需重新配对, 请选择此项# 原本的房友配对和Ticket号码会被取消。':''}		
							
						</div>
						<br />
						<br />
						<div> 
						{/* <input type='button' onClick={()=>cancelapply() } value={(lang=='eng'?'Click here to Cancel Ticket':'') + (lang=='tc'?'如取消Ticket,請按這裡':'') + (lang=='sc'?'如取消Ticket,请按这裡':'')} /> 
						*/} 
							
						</div>						
						
					</div>}					
					
					<br />		
					<br />		
					<br />	
				
			</div>
				
		</Context.Provider>
  );
}

export default App;
