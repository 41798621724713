export const URL0 = "https://nao.cuhk.bctshk.com";
export const blue = '#7d2882';

export const attachTypeLabel = {
	
	non:{"tc":'-',
			"eng":'-',
			"sc":'-',
			},
	
	addr:{"tc":'住址証明文件',
			"eng":'Proof of Residential Address',
			"sc":'住址证明文件',
			},
	area:{"tc":'面積證明',
			"eng":'Area Testimonial',
			"sc":'面积证明',
			},
			
	regist:{"tc":'宿生登記表',
			"eng":'Resident Registration Form',
			"sc":'宿生登记表',
			},
			
	exchange:{"tc":'申請與交換生為房友',
			"eng":'Application for an Exchange Student Room-mate ',
			"sc":'申请与交换生为房友',
			},
			
			
	nonlocal:{"tc":'申請與非本地本科生為房友',
			"eng":'Application for a Non-local Student Room-mate',
			"sc":'申请与非本地本科生为房友',
			},
			
	specific:{"tc":'申請入住指定宿舍',
			"eng":'Application for Residence at A Specific Student Hostel',
			"sc":'申请入住指定宿舍',
			},
			
	gmap:{"tc":'谷歌地圖擷圖',
			"eng":'Google Map Screen Capture',
			"sc":'谷歌地图撷图',
			},	


	walkforgreen:{"tc":'「大步行參加者」證明',
			"eng":'Proof of Participant of Walk for Green',
			"sc":'「大步行参加者」证明',
			},			
			
	health:{"tc":'健康',
			"eng":'Health',
			"sc":'健康',
			},

	family:{"tc":'家庭',
			"eng":'Family',
			"sc":'家庭',
			},				
}
